<template>
	<article>
		<hero-image
			:alt="hero.alt"
			:heading="hero.heading"
			:img-small="hero.imgSmall"
			:img-large="hero.imgLarge"
		/>
		<v-container class="py-0">
			<v-row no-gutters align="center">
				<v-col :order="$vuetify.breakpoint.xsOnly ? 'last' : null" cols="12" sm="7">
					<breadcrumbs :crumbs="crumbs" />
				</v-col>
				<v-col cols="12" sm="5">
					<social :meta="meta" />
				</v-col>
			</v-row>
		</v-container>
		<v-container class="content" tag="section">
			<v-row>
				<v-col cols="12">
					<p class="blockquote">
						An assessment of intellectual functioning, or IQ, can be conducted when there are concerns about your child's cognitive abilities (for
						example your child is not learning like other kids their age etc). IQ assessments are also conducted for children who are bright or
						‘gifted’.
					</p>
					<p>
						An IQ assessment can help to give a greater understanding of a child's strengths and weaknesses and will allow for the provision of
						recommendations regarding academic, social and emotional support. Early identification of strengths and difficulties is important to
						allow for nurturing and support to allow gifted children apply and use their abilities.
					</p>
				</v-col>
				<v-col cols="12">
					<cta :color="$vuetify.theme.themes.light.intellectual" icon="$vuetify.icons.intellectual" />
				</v-col>
			</v-row>
		</v-container>
	</article>
</template>

<script>
import { _MxAppViewClass, _MxMeta } from '@/mixins/global';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs';
import Cta from '@/components/cta/Cta';
import HeroImage from '@/components/image/HeroImage';
import Social from '@/components/social/Social';
export default {
	name: 'IntellectualFunctioning',
	components: {
		Breadcrumbs,
		Cta,
		HeroImage,
		Social
	},
	mixins: [_MxAppViewClass, _MxMeta],
	data() {
		return {
			crumbs: [
				{
					text: 'home',
					disabled: false,
					to: '/'
				},
				{
					text: 'services',
					disabled: false,
					to: '/services'
				},
				{
					text: this.$route.name,
					disabled: true
				}
			],
			hero: {
				alt: this.$route.name,
				heading: `${this.$route.name} Assessment`,
				imgSmall: 'hero-02-small',
				imgLarge: 'hero-02-large'
			},
			meta: {
				description: 'Intellectual Functioning Neuropsychological Assessment for your child or adolescent from Sydney Kids Neuropsychology.',
				title: 'Intellectual Functioning IQ'
			}
		};
	}
};
</script>
